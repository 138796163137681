<template>
	<div class="p-grid">
		<div class="p-col-12">
			<Toast/>
			<div class="card p-fluid">
				<Fieldset class="p-m-2" :legend="tituloCtaHabiente">
					<div id="sucursal" class="p-fluid p-field" style="display: none">
						<label for="id_sucursal">Sucursal</label>
						<Dropdown v-model="mov.id_sucursal" :options="lkpSucursales" optionLabel="sucursal" optionValue="id_sucursal" placeholder="Sucursal..." />						
					</div>				
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1">
							<label for="tipo_documento">Tipo Documento</label>
							<Dropdown v-model="mov.id_movimiento_tipo" @change="cargarInfoMovimiento()"  :options="lkpMovimientosTipo" optionLabel="movimiento_tipo" optionValue="id_movimiento_tipo" placeholder="Tipo de Documento" />
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1" >
							<label for="serie">Serie</label>
							<Dropdown v-model="mov.id_movimiento_serie" :options="lkpSeries" optionLabel="serie" optionValue="id_movimiento_serie" placeholder="Serie" @change="cargarSerieCorrelativo"/>						
						</div>
						<div class="p-field p-m-1">
							<label for="no_docto">No. Documento</label>
							<InputText id="no_docto" v-model="documento" disabled />						
						</div>
					</div>	
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1">
							<label for="icon">Fecha</label>
							<Calendar id="icon" v-model="mov.fecha" :showIcon="true" :manualInput="false" dateFormat="dd-MM-yy"/>
						</div>
						<div class="p-field p-m-1">
							<label for="id_condicion_pago">Moneda</label>
							<Dropdown v-model="mov.id_moneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" @change="cargarCuotasPendientes" placeholder="Seleccionar"/>
						</div>								
					</div>						
					<div class="p-fluid p-formgrid p-grid">
						<div v-if="appMode==0" class="p-field p-m-1">
							<label for="cuentahabiente">Cuentahabiente</label>
							<div class="p-fluid p-inputgroup">
							<InputText size="135" id="cuentahabiente" type="text" v-model="mov.nombre" disabled/>																							
							<Button icon="pi pi-search" @click="seleccionarCtaHabiente"/>							
							</div>
						</div>		
						<div v-if="appMode==1" class="p-field p-m-1">
						<label for="inmueble">Inmueble</label>
						<div class="p-inputgroup">
							<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																							
							<Button icon="pi pi-search" @click="seleccionarInmueble" />							
						</div>					
						</div>												
					</div>	
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1">
							<label for="observaciones">Comentarios</label>
							<Textarea id="observaciones" v-model="mov.observaciones" :autoResize="true" rows="2" cols="125" />
						</div>	
					</div>	
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-m-1" v-if="!requirePago">
							<label for="total">Monto</label>
							<InputNumber id="total" v-model="mov.total" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"/>					
						</div>	
					</div>					
				</Fieldset>	
	
				<Fieldset legend="Formas de Pago" class="p-m-2" v-if="requirePago">
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Agregar" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevaFormaPago" />
					</template>
				</Toolbar>


				<DataTable :value="mov.pagos" class="p-datatable-sm" :scrollable="true" scrollHeight="flex" selectionMode="single" dataKey="id_movimiento_pago">
				<Column field="forma_pago" header="Forma Pago" style="min-width:200px"></Column>
				<Column field="emisor" header="Emisor" style="min-width:200px"></Column>
				<Column field="referencia" header="#Referencia" style="min-width:200px"></Column>
				<Column field="total" header="Total"  dataType="numeric" style="min-width: 8rem">
					<template #body="{data}">
						{{formatCurrency(data.total)}}
					</template>
				</Column>				
				<Column>
					<template #body="slotProps">
						<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteFormaPago(slotProps.data)" />
					</template>
				</Column>	
				<ColumnGroup type="footer">
					<Row>
						<Column footer="Total:" :colspan="3" footerStyle="text-align:right"/>
						<Column :footer="totalPagos" />
					</Row>
				</ColumnGroup>							
				</DataTable>					

				</Fieldset>	

				<fieldset class="p-m-2" v-if="!autoaplicar" legend="Documentos con Saldo">					
				<DataTable :value="lkpCargosPendientes" :rowClass="rowClass"  @row-dblclick="aplicarCargo" class="p-datatable-sm" 
				:scrollable="true" scrollHeight="flex" selectionMode="single"  dataKey="id_cuota">
				<Column field="fecha" header="Fecha" style="min-width:200px"></Column>
				<Column field="nombre" header="Tipo"></Column>	
				<Column field="no_docto" header="No.Docto">
					<template #body="slotProps">							
						{{slotProps.data.serie}}-{{ formatDocument(slotProps.data.no_docto) }}
					</template>				
				</Column>	
				<Column field="fel_serie" header="#FEL" :style="felHabilitado?'':'display: none'">
					<template #body="slotProps">							
						{{slotProps.data.fel_serie}}-{{ slotProps.data.fel_numero }}
					</template>								
				</Column>
				<Column field="referencia" header="Referencia"/>						
				<Column field="fecha_pago" header="Fecha Pago"></Column>		
				<Column field="saldo" header="Saldo" dataType="numeric" style="min-width: 8rem">
					<template #body="{data}">
						{{formatCurrency(data.saldo)}}
					</template>
				</Column>	
				<Column field="pago" header="Pago" dataType="numeric" style="min-width: 8rem">
					<template #body="{data}">
						<InputNumber v-bind:id="data.id_cuota" v-model="data.pago" mode="decimal" :minFractionDigits="2" :min="0.00" v-bind:max="data.saldo" />	
					</template>
				</Column>																								
				<ColumnGroup type="footer">
					<Row>
						<Column footer="Documentos:" :colspan="1" footerStyle="text-align:right" />
						<Column :footer="lkpCargosPendientes.length"/>
						<Column footer="Saldo Total:" :colspan="3" footerStyle="text-align:right"/>
						<Column :footer="formatCurrency(saldoTotal)" />
						<Column footer="Total a Pagar:" :colspan="3" footerStyle="text-align:right" />
						<Column :footer="formatCurrency(totalADocumentoAPagar)" footerStyle="text-align:left"/>			
					</Row>
				</ColumnGroup>							
				</DataTable>						
				</fieldset>
				<!-- <Button label="Registrar" @click="registrarMov" icon="pi pi-check" :disabled="(puedeRegistrar)&&(!guardando)"/>		 -->
				<Button label="Registrar" @click="registrarMov" icon="pi pi-check" :disabled="puedeRegistrar"/>						
			</div>
		</div>

		<Dialog v-model:visible="loading" :modal="true" :closable="false"  :breakpoints="{'960px': '75vw', '640px': '90vw'}"  :style="{width: '150px'}">
			<div class="flex align-content-center flex-wrap card-container">
				<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
					<ProgressSpinner style="width:50px;height:50px"/>
				</div>
				<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
				<p>Procesando...</p>
				</div>
			</div>
		</Dialog>

		<Dialog v-model:visible="formaPagoDlg" :style="{width: '450px', height: '400PX'}" header="Forma de Pago" :modal="true" class="p-fluid" >
			<!-- <img :src="'assets/demo/images/product/' + product.image" :alt="product.image" class="product-image" v-if="product.image" /> -->
			<template #header>
				<div class="p-field p-text-bold p-m-1">
					Forma de Pago				
				</div>
			</template>
			<div id="formaPagoDlgBody" :style="{height: '400px'}">
				<div class="p-field">
					<label for="id_forma_pago">Metodo de Pago</label>
					<Dropdown v-model="forma_pago.id_forma_pago" :options="lkpFormasPago" optionLabel="nombre" optionValue="id_forma_pago" @change="configurarFormaPago" placeholder="Seleccionar..." />
				</div>
				<div v-if="mostrarEmisor" class="p-field" id="emisor">
					<label for="id_emisor">Emisor</label>
					<Dropdown v-model="forma_pago.id_emisor" :options="lkpEmisores" optionLabel="emisor" optionValue="id_emisor" placeholder="Seleccionar..." />
				</div>			
				<div v-if="mostrarReferencia" class="p-field" id="referencia">
					<label for="referencia"># Referencia</label>
					<InputText id="referencia" v-model.trim="forma_pago.referencia" />
				</div>
				<div class="p-formgrid p-grid">
					<div class="p-field p-col">
						<label for="total">Monto</label>
						<InputNumber id="total" v-model="forma_pago.total" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"/>					
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="cancelarFormaPago"/>
				<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="validarFormaPago" />
			</template>
		</Dialog>

		<BuscarCtaHabiente :key="dlgBuscarCtaHabienteRefresh" :mostrar="ctaHabienteDialog" @cancelar="cerrarDlgCtahabiente"  @seleccionar="elegirCtaHabiente"/>
		<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
		
		<ConfirmacionEnvioEmails :key="dlgConfirmarEmailsRefresh" :destinatarios="emails" :mostrar="dlgEnvioEmail" @cancelar="cancelarEnvioXEmail"  @confirmacion="enviarEmailTrx"/>

		<Dialog v-model:visible="deleteFormaPagoDlg" :style="{width: '450px'}" header="Confirmar" :modal="true">
			<div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<span>Está seguro que desea remover la forma de pago seleccionada?</span>
			</div>
			<template #footer>
			<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteFormaPagoDlg = false"/>
			<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteFormaPago" />
			</template>
		</Dialog>

		<Dialog v-model:visible="formaPagoDuplicadaDlg" :style="{width: '450px'}" header="Comprobante de Pago Duplicado" :modal="true">
			<div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<span>El comprobante de pago que intenta registrar ya esta en uso.</span>
			<span>¿Está seguro que desea continuar?</span>
			</div>
			<template #footer>
			<Button label="No" icon="pi pi-times" class="p-button-text" @click="formaPagoDuplicadaDlg = false"/>
			<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="aceptarFormaPago" />
			</template>
		</Dialog>

        <Dialog v-model:visible="dlgValidacion" :style="{width: '50vw'}" :modal="true">
			<template #header>
				<i class="pi pi-info-circle" style="fontSize: 2rem"></i>
				<p class="p-m-0">{{ headerValidacion }}</p>
			</template>
            <p class="p-m-0">{{ mensajeValidacion }}</p>
            <template #footer>
                <Button label="Aceptar" icon="pi pi-check" @click="cerrarDlgValidacion" autofocus />
            </template>
        </Dialog>
	</div>
</template>

<script>
import BuscarCtaHabiente from '../components/BuscarCtaHabiente.vue';
import MdsBuscarInmueble from '../components/MdsBuscarInmueble.vue';
import ConfirmacionEnvioEmails from '../components/ConfirmacionEnvioEmails.vue';
import CxcService from '../service/cxc.service';
import { uuid } from 'vue-uuid'; 
import { CURRENT_APP_MODE } from '../service/constants';

export default {
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			InmuebleSel: null,
			InmuebleDisplay: 'Seleccionar...',
			inmuebleDialog: false,
			felHabilitado: this.$store.getters['auth/felHabilitado'],
			puedeEnviarEmail: (this.$store.getters['auth/tieneAcceso']('CC_EMITIR_COMPRABANTE_PAGO')),
			puedeDuplicaComprobantePago:  (this.$store.getters['auth/tieneAcceso']('CC_DUPLICAR_COMPROPANTE_PAGO')),
			tituloCtaHabiente: (CURRENT_APP_MODE == 0 ? 'Cuentahabiente': 'Inmueble'),
			headerValidacion: "",
			mensajeValidacion: "",
			formaPagoDuplicadaDlg: false,
			dlgValidacion: false,
			autoaplicar: false,
			selCtaHabiente: null,			
			ctaHabienteDialog: false,
			dlgBuscarCtaHabienteRefresh: 0,
			ctaHabienteDialogLoading: false,
			requirePago: true,
			formaPagoDlg: false,
			mostrarEmisor: false,
			mostrarReferencia: false,
			deleteFormaPagoDlg: false,
			filtroBusquedaCtaHabiente: "",
			lkpSucursales: this.$store.state.auth.currentAppCtx.Sucursales,
			// lkpMovimientosTipo: this.$store.state.auth.currentAppCtx.tiposMovimientoCxc.filter(tipo => tipo.requiere_pago == 'S'),
			lkpMovimientosTipo: this.$store.state.auth.currentAppCtx.tiposMovimientoCxc,//.filter(tipo => tipo.activo == 'S'),
			lkpFormasPago: this.$store.state.auth.currentAppCtx.formasPago,
			lkpEmisores: this.$store.state.auth.currentAppCtx.Emisores,
			lkpCtaHabientes: [],
			lkpSeries: null,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			IdMonedaDefault: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
			EsMonedaBase: true,
			IdMovimientoTipoDefault: null,
			selectedCargo: null,
			lkpCargosPendientes: [],
			dlgEnvioEmail: false,
			dlgConfirmarEmailsRefresh: 0,
			emails: [],
			saldoTotal: 0.0,
			totalAPagar: 0.0,
			loading: false,
			forma_pago: false,
			guardando: false,
			mov: {
				no_docto: 0,
				pagos:[],
				abonos:[]
			}
		}
	},
	components: { BuscarCtaHabiente, MdsBuscarInmueble, ConfirmacionEnvioEmails },	
	mounted()
	{		
		let tipoMov = this.lkpMovimientosTipo.find(tp => tp.requiere_pago == 'S');
				
		if(tipoMov != null){
			this.IdMovimientoTipoDefault = tipoMov	
		} else {
			this.IdMovimientoTipoDefault = null					
		}

		this.mov = this.newRec();
		this.cargarInfoMovimiento();
	},
	computed:
	{
		puedeRegistrar(){
			return !((this.mov.id_cta_habiente_info != '') && (this.mov.total > 0.00));
		},
		ctaHabienteSelected() {
			return !(this.selCtaHabiente);
		},
		totalPagos() {
			let total = 0;
			for(let pago of this.mov.pagos) {
				total += pago.total;
			}

			return this.formatCurrency(total);
		},	
		documento()
		{
			return this.mov.no_docto.toString().padStart(6, '0');
		},		
		totalADocumentoAPagar() {
			var totalAPagar = 0.0;
			for(let doc of this.lkpCargosPendientes) {
				totalAPagar += doc.pago;
			}

			return totalAPagar;
		},
		puedeEnviarEmail()
		{
			return (this.emails.length > 0);
		}
	},
	methods: {
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},		
		formatDocument(Docto)
		{
			return Docto.toString().padStart(6, '0');
		},			
		rowClass(data) {
			var vencido = (Date.parse(data.fecha_pago)<Date.now());

			return (vencido?'row-vencido': null);
		},
		aplicarCargo(event){
			var pago = event.data.pago;

			if (pago == 0) {
				pago = event.data.saldo;
			} else {
				pago = 0;
			}
			event.data.pago = pago;
		},
		showDlgValidacion(titulo, mensaje){		
			this.headerValidacion = titulo;	
			this.mensajeValidacion = mensaje;
			this.dlgValidacion = true;
		},
		cerrarDlgValidacion() {
			this.mensajeValidacion = "";
			this.dlgValidacion = false;
		},
		newRec()
		{
			let idMovimientoTipo = this.IdMovimientoTipoDefault != null? this.IdMovimientoTipoDefault:this.lkpMovimientosTipo[0];
			
			var rec = {
				id_movimiento: "",
				id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
				id_empleado: this.$store.state.auth.currentAppCtx.id_empleado,
				id_cta_habiente_info: "",
				id_moneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
				id_movimiento_serie: "",
				id_caja_jornada: "",
				id_sucursal: this.lkpSucursales[0].id_sucursal,
				id_movimiento_tipo: idMovimientoTipo.id_movimiento_tipo,
				id_mov_asociado: "",
				no_docto: 0,
				fecha: new Date(),
				nit: "",
				nombre: "",
				direccion: "",
				usuario: "",
				referencia: "",
				pct_comision: 0.0,
				comision: 0.0,
				comision_aplicada: 0.0,
				comision_saldo: 0.0,
				tasa_cambio: 1.0,
				total: 0.0,
				observaciones: "",
				pagos: [],
				abonos: []
			};
			this.emails = [];
			this.InmuebleDisplay = 'Seleccionar...';
			return rec;
		},
		mostrarCargosPendientes()
		{
			if(!this.autoaplicar){
				this.cargarCuotasPendientes();
			}
		},
		cargarCuotasPendientes()
		{
			this.loading = true;
			CxcService.getCuotasConSaldo(this.$store.state.auth.currentAppCtx, this.mov.id_cta_habiente_info, this.mov.id_moneda).then(data => 
			{
				this.loading = false;				
				this.lkpCargosPendientes = data;

				this.totalAPagar = 0.0;
				this.saldoTotal = 0.0;
				let i;
				for (i = 0; i < this.lkpCargosPendientes.length; i++) {
					this.saldoTotal += this.lkpCargosPendientes[i].saldo;
				}				
			}	
			).catch(data => {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			})	
		},
		cargarInfoMovimiento()
		{		
			let tipoMov = this.lkpMovimientosTipo.find(tp => tp.id_movimiento_tipo == this.mov.id_movimiento_tipo);
			this.requirePago = (tipoMov.requiere_pago == "S");
			this.mov.pagos = [];

			CxcService.getSeriesXTipoMovimiento(this.$store.state.auth.currentAppCtx, this.mov.id_movimiento_tipo, this.mov.id_sucursal).then(data => 
			{
				this.loading = false;
				this.lkpSeries = data;
				if(this.lkpSeries.length > 0)
				{
					this.mov.id_movimiento_serie = this.lkpSeries[0].id_movimiento_serie;
					this.cargarSerieCorrelativo();
				}
			}	
			).catch(data => {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			})		
		},
		cargarSerieCorrelativo()
		{
			let serie = this.lkpSeries.find(s=> s.id_movimiento_serie == this.mov.id_movimiento_serie)
			
			if(serie){
				this.mov.id_movimiento_serie = serie.id_movimiento_serie;
				this.mov.no_docto = serie.proximo;			
			}
		},
		configurarFormaPago()
		{			
			let fp = this.lkpFormasPago.filter(f => f.id_forma_pago == this.forma_pago.id_forma_pago)
			this.mostrarEmisor = (fp[0].requiere_emisor == "S");
			this.mostrarReferencia = (fp[0].requiere_documento == "S");
		},
		nuevaFormaPago() {
			this.forma_pago = {
			id_movimiento_pago: uuid.v4(),
			id_movimiento: "",
			id_forma_pago: this.lkpFormasPago[0].id_forma_pago,
			forma_pago: "",
			id_emisor: "",
			emisor: "",
			autorizacion: "",
			referencia: "",
			no_docto: 0,
			sub_total: 0.0,
			pct_cargo_extra: 0.0,
			cargo_extra: 0.0,
			total: 0.0			
			};
			this.mostrarEmisor = (this.lkpFormasPago[0].requiere_emisor == 'S');
			this.mostrarReferencia = (this.lkpFormasPago[0].requiere_documento == 'S');
			this.formaPagoDlg = true;
		},
		cancelarFormaPago()
		{
			this.forma_pago = {};
			this.formaPagoDlg = false;
		},
		async validarFormaPago()
		{
			if(this.forma_pago.total == 0.0) {
				this.showDlgValidacion('Monto Incorrecto', 'El monto debe ser un valor mayor a cero!');
				exit;
			}

			if(!this.mostrarReferencia) {
				this.guardarFormaPago();
				exit;
			} 

			this.loading = true;
			try {
				let mov = {
					id_empresa : this.$store.state.auth.currentAppCtx.id_referencia,
					Pagos: [
						{
							id_forma_pago: this.forma_pago.id_forma_pago,
							id_emisor: this.forma_pago.id_emisor,
							autorizacion: this.forma_pago.autorizacion,
							no_docto: this.forma_pago.no_docto,
							referencia: this.forma_pago.referencia,
							sub_total: 0,
							pct_cargo_extra: 0.0,
							cargo_extra: 0.0,
							total: this.forma_pago.total
						}
					]					
				};

				let ret = await CxcService.formaPagoDuplicada(this.$store.state.auth.currentAppCtx, mov);
				this.loading = false;				
				

				if(ret.result) {
					if(this.puedeDuplicaComprobantePago) {
						this.formaPagoDuplicadaDlg = true;
					} else {
						this.showDlgValidacion('Comprobante de Pago Duplicado', 'El comprobante de pago que intenta registrar ya esta en uso!');
					}
				} else
				{
					this.aceptarFormaPago();
				}
							
			} catch (error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})				
			}
		},
		aceptarFormaPago(){
			this.formaPagoDuplicadaDlg = false;
			this.guardarFormaPago();
		},
		guardarFormaPago()
		{					
			if(this.forma_pago.total <= 0.00)
			{
				this.showDlgValidacion('Monto Inválido!', 'El monto debe ser mayor a cero!');
			} else 
			{
				this.forma_pago.forma_pago = this.lkpFormasPago.filter(fp => fp.id_forma_pago == this.forma_pago.id_forma_pago)[0].nombre;
					
				if(!this.mostrarEmisor)
				{
					this.forma_pago.id_emisor = '';
				}

				if(!this.mostrarReferencia)
				{
					this.forma_pago.referencia = '';
				}

				if(this.forma_pago.id_emisor != '')
				{
					this.forma_pago.emisor = this.lkpEmisores.filter(em => em.id_emisor == this.forma_pago.id_emisor)[0].emisor;
				} else {
					this.forma_pago.emisor = '';
				}
				this.forma_pago.sub_total = this.forma_pago.total;

				this.mov.pagos.push(this.forma_pago);
				this.mov.total += this.forma_pago.total;
				this.formaPagoDlg = false;
			}
		},	
		confirmDeleteFormaPago(formapago) {
			this.forma_pago = formapago;
			this.deleteFormaPagoDlg = true;
		},		
		deleteFormaPago()
		{
			this.mov.pagos = this.mov.pagos.filter(val => val.id_movimiento_pago !== this.forma_pago.id_movimiento_pago);
			this.mov.total -= this.forma_pago.total;
			this.deleteFormaPagoDlg = false;
			this.forma_pago = {};		
		},	
		seleccionarCtaHabiente() {
			this.ctaHabienteDialog = true;
			this.dlgBuscarCtaHabienteRefresh++;
		},
		cerrarDlgCtahabiente(){
			this.ctaHabienteDialog = false;
		},
		elegirCtaHabiente(CtaHabiente){
			this.ctaHabienteDialog = false;
			
			this.selCtaHabiente = CtaHabiente;
			this.mov.id_cta_habiente_info = CtaHabiente.id_cta_habiente_info;
			this.mov.nombre = CtaHabiente.nombre_comercial;
			this.mov.nit = CtaHabiente.nit;

			this.cargarCuotasPendientes();
		},
		seleccionarInmueble() {
			this.inmuebleDialog = true;
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){
			this.inmuebleDialog = false;
			this.InmuebleSel = Inmueble;
			this.InmuebleDisplay = Inmueble.display;
			this.mov.id_cta_habiente_info = Inmueble.id_cta_habiente_info;
			this.mov.nombre = Inmueble.nombre_documento;
			this.mov.nit = Inmueble.nit_documento;
			this.mov.direccion = Inmueble.direccion_documento;
			this.emails = Inmueble.emails;

			this.cargarCuotasPendientes();
		},
		confirmarEnvioXEmail(Destinatarios) {
			this.emails = Destinatarios;
			this.dlgConfirmarEmailsRefresh++;
			this.dlgEnvioEmail = true;			
		},
		async cancelarEnvioXEmail() {
			this.dlgEnvioEmail = false;
			this.loading = true;
			try {
				await this.nuevoMov();
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},
		async enviarEmailTrx(Emails) {
			this.dlgEnvioEmail = false;
			this.loading = true;
			try {				
				var notif = {						
					id: this.mov.id_movimiento,
					emails: Emails
				};

				await CxcService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);

				await this.nuevoMov();

				this.loading = false;
			
			} catch(error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
			}			
		},
		async nuevoMov() {
			this.dlgEnvioEmail = false;
			this.InmuebleSel = null;
			this.mov = {};
			this.mov = this.newRec();
			this.selCtaHabiente = null;
			this.lkpCargosPendientes = [];		
			this.saldoTotal	= 0.0;
			this.cargarInfoMovimiento();					
		},
		async registrarMov() {
			this.guardando = true;
			if(this.autoaplicar)
			{
				this.mov.abonos = [];
			} else {
				let abonos = this.lkpCargosPendientes.filter(a => a.pago > 0.00);
				this.mov.abonos = [];
				
				let a;
				var totalAplicar = 0.0;

				for(a in abonos)				
				{
					//totalAplicar += abonos[a].pago;
					totalAplicar += Math.round((abonos[a].pago + Number.EPSILON) * 100) / 100;

					this.mov.abonos.push(
							{			
							id_movimiento_detalle: "",
							id_movimiento: "",
							id_cuota: abonos[a].id_cuota,
							anulado: "N",
							fecha_creacion: this.mov.fecha,
							monto_original: abonos[a].monto,
							aplicado: abonos[a].pago,
							saldo: abonos[a].monto - abonos[a].pago,
							monto: abonos[a].pago,
							mora: 0.0
							})	
				}
			}
			totalAplicar = Math.round((totalAplicar + Number.EPSILON) * 100) / 100; //redondeo a 2 digitos
			
			if(totalAplicar > this.mov.total)
			{
				this.showDlgValidacion("Monto Insuficiente", "El total a aplicar es mayor al monto del pago. Por favor revise los valor antes de continuar!");
			} else {
				this.loading = true;
				CxcService.registrarMovimiento(this.$store.state.auth.currentAppCtx, this.mov).then((data) =>
				{					
						this.$toast.add({severity:'success', summary: 'Éxito', detail: 'El pago ha sido registrado con éxito!', life: 3000});
						this.mov.id_movimiento = data.id;		
						
						/* Si hay un inmueble, es decir que es modo Multidomus, confirmar si tiene preferencia de envio de emails,
							si es modo Merkantus no hay inmueble si siempre se debe pedir confirmacion para envio de emails
						*/
						var enviarNotificacion = true;

						if(this.appMode == 1) {
							enviarNotificacion = (this.InmuebleSel?this.InmuebleSel.enviar_notificacion: false);
						}
						/* 
						Si hay un inmueble, es decir que es modo Multidomus, confirmar si tiene preferencia de envio de emails,
						si es modo Merkantus no hay inmueble si siempre se debe pedir confirmacion para envio de emails
						*/
						if(this.puedeEnviarEmail && enviarNotificacion) {
							if(this.InmuebleSel) {
								this.confirmarEnvioXEmail(this.InmuebleSel.emails);
							} else {
								this.confirmarEnvioXEmail(this.selCtaHabiente.emails);
							}
						} else {
							this.loading = true;
							
							this.nuevoMov();				

							this.loading = false;
						}	
						
						this.guardando = false;
					}
				).catch(
					data => {
						this.guardando = false;
						this.loading = false;
						console.log("error: " + data);
						this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
					}
				);	
			}			
		}
	},
}
</script>

<style scoped>

::v-deep(.row-vencido) {
    color: #9c021e;
}
</style>
