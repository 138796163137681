<template>
		<Dialog header="Header" v-model:visible="mostrarDlg" :style="{width: '50vw'}" :modal="true" :closable="false" :closeOnEscape="true">
			<template #header>				
				Buscar Inmueble
			</template>
				<div class="p-fluid p-field">
					<div class="p-inputgroup">
						<InputText id="filtro" type="text" placeholder="Escriba el criterio de búsqueda" v-model="filtroBusquedaInmueble" @keyup.enter="buscar" autofocus/>
						<Button icon="pi pi-search" @click="buscar"/>
					</div>
				</div>				
				<div class="p-fluid p-field">
					<DataTable ref="resultados" :value="lkpInmuebles" :scrollable="true" scrollHeight="flex" :loading="inmuebleDialogLoading"
					v-model:selection="selInmueble" selectionMode="single" dataKey="id_inmueble" row-select="seleccionar" >
						<Column field="display" header="Inmueble" style="min-width:100px"></Column>
						<Column field="codigo" header="Código" style="min-width:300"></Column>
						<Column field="activo" header="Activo" style="min-width:100px" bodyStyle="text-align: center">
							<template #body="slotProps">							
								<i v-if="slotProps.data.activo" class="pi pi-circle-on"></i>
								<i v-else class="pi pi-circle-off"></i>							
							</template>	
						</Column>
						<template #empty>
							<div>
							No hay datos para mostrar
							</div>
						</template>
					</DataTable>				
				</div>			
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" @click="cancelar" class="p-button-text"/>
				<Button label="Seleccionar" icon="pi pi-check" @click="elegir" autofocus :disabled="inmuebleSelected"/>
			</template>
		</Dialog>
</template>

<script>
	import MdsService from '../service/mds.service';
	
	export default {
		name: 'MdsBuscarInmueble',
		props: {
			mostrar: {type: Boolean, required: true},
			filtro: {type: String, required: false, default: ''}
		},
		data() {
				return {
					selInmueble: null,
					inmuebleDialog: null,				
					inmuebleDialogLoading: false,
					filtroBusquedaInmueble: this.filtro,
					identificador1: "",
					identificador2: "",
					identificador3: "",
					identificador4: "",
					identificador5: "",
					lkpInmuebles: []
				}
		},
		computed: {
			mostrarDlg() {
				return this.mostrar;
			},
			inmuebleSelected() {
				return !(this.selInmueble);
			}		
		},
		methods: {
			mostrarResultadosBusqueda(filtro, resultados){
				this.filtroBusquedaEntidad = filtro;
				this.lkpInmuebles = resultados;
			},
			cancelar() {
				this.visible = false;
				this.$emit('cancelar');				
			},
			seleccionar(){
				if(this.lkpInmuebles.length == 1)
				{
					this.elegir();
				}
			},
			async elegir() {				
				this.filtroBusquedaEntidad = '';

				this.inmuebleDialogLoading = true;
				try {
					var data = await MdsService.getInmuebles(this.$store.state.auth.currentAppCtx, this.selInmueble.id_inmueble);
					this.inmuebleDialogLoading = false;
					data.activoDisplay = (data.activo === 'S');
					data.display = this.selInmueble.display;
					data.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, data.id_cta_habiente_info);
					this.$emit('seleccionar', data);
					this.lkpInmuebles = [];
					this.selInmueble = null;
				} catch (error) {
						this.inmuebleDialogLoading = false;
						this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
				}
			},
			buscar() {
				this.inmuebleDialogLoading = true;
				this.selInmueble = null;
				let filtro = {
					id_complejo: `${this.$store.state.auth.currentAppCtx.complejo.id_complejo}`,
					identificador1: `${this.identificador1}`,
					identificador2: `${this.identificador2}`,
					identificador3: `${this.identificador3}`,
					identificador4: `${this.identificador4}`,
					identificador5: `${this.identificador5}`,
					filtro: `${this.filtroBusquedaInmueble}`
				};

				MdsService.buscarInmuebles(this.$store.state.auth.currentAppCtx, filtro).then(data => 
				{
					this.lkpInmuebles = data;
					this.inmuebleDialogLoading = false;				
				}	
				).catch(
					data => {
					this.inmuebleDialogLoading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}				
				);		
			}			
		}
	}
</script>

<style scoped>

</style>
