import axios from 'axios';
import {authHeader, authHeaderMultiPart}  from './auth-header';
import authService from './auth.service';
import { API_URL_CATALOGO,  API_URL_CXC,  ERR_MSG_CONEXION } from './constants';

class CxcService {

  getTiposMovimiento(AppCtx, RequirePago, SoloActivos)
  {
    return axios.post(API_URL_CATALOGO + '/cxcmovimientotipos', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${AppCtx.id_referencia}`,
        RequierePago: `${RequirePago}`,
        SoloActivos:`${SoloActivos}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
 
  getPerfilesImportacion(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/cxcperfilesimportacion', {
        AppCtx: `${AppCtx.codigo}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getPerfilesExportacion(AppCtx)
  {
    return axios.post(API_URL_CATALOGO + '/cxcperfilesexportacion', {
        AppCtx: `${AppCtx.codigo}`,
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  cargarArchivoPagos(AppCtx, complemento, Archivo)
  {
    let formData = new FormData();
    
    formData.append("appctx", AppCtx.codigo);
    formData.append("id_perfil", complemento.id_perfil);    
    formData.append("id_empleado", complemento.id_empleado);    
    formData.append("file", Archivo);

    let auth = authHeaderMultiPart();

    return axios.post(API_URL_CXC + '/preprocesararchivopagos',
    formData, 
    { headers: auth }).then(response => {
      return response.data;
    }
    ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  procesarPagosBatch(AppCtx, Batch) {
    
    let data = JSON.stringify(Batch);

    return axios.post(API_URL_CXC+ '/procesarbatchpagos', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );    
  }

  getArchivoSaldos(AppCtx, IdPerfil) {
      return axios.post(API_URL_CXC + '/getarchivosaldos', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${IdPerfil}`
      }, 
      { 
        headers: authHeader(),
        responseType: 'blob'
      }).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );  
  }

  registrarMovimiento(AppCtx, Movimiento)
  {
    let data = JSON.stringify(Movimiento);

    return axios.post(API_URL_CXC+ '/registrartrx', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  registrarAbono(AppCtx, Movimiento)
  {
    let data = JSON.stringify(Movimiento);

    return axios.post(API_URL_CXC+ '/RegistrarAbono', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  anularMovimiento(AppCtx, movimiento)
  {
    return axios.post(API_URL_CXC+ '/anulartrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${movimiento.id_movimiento}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
  formaPagoDuplicada(AppCtx, Movimiento)
  {
    let data = JSON.stringify(Movimiento);

    return axios.post(API_URL_CXC+ '/formapagoduplicada', {
        AppCtx: `${AppCtx.codigo}`,
        Data:`${data}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getResumenSaldos(AppCtx)
  {
    return axios.post(API_URL_CXC + '/getresumensaldos', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${AppCtx.id_referencia}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getKpis(AppCtx, Index)
  {
    return axios.post(API_URL_CXC + '/getkpis', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${AppCtx.id_referencia}`,
        Index: Index
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getFormatoImpresionTrx(AppCtx, movimiento, notif)
  {    
    return axios.post(API_URL_CXC + '/getrpttrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${movimiento.id_movimiento}`,
        Notificar: notif
      }, 
      { 
        headers: authHeader(),
        responseType: 'blob'
      }).then(response => {
        return response;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  enviarEmailTrx(AppCtx, Notif)
  {    
    return axios.post(API_URL_CXC + '/enviarnotificacionestrx', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Notif.id}`,
        Data: `${JSON.stringify(Notif.emails)}`
      }, 
      { 
        headers: authHeader()
      }).then(response => {
        return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }

  getSeriesXTipoMovimiento(AppCtx, Id, IdSucursal)
  {
    return axios.post(API_URL_CATALOGO + '/cxcmovimientoseries', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${Id}`,
        IdSucursal: `${IdSucursal}`
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
  getCuotasConSaldo(AppCtx, Id, IdMoneda)
  {
    return axios.post(API_URL_CXC + '/getcuotasconsaldo', {
      AppCtx: `${AppCtx.codigo}`,
      Id:`${Id}`,
      IdMoneda: `${IdMoneda}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  getHistorialTrxs(AppCtx, Data)
  {
    return axios.post(API_URL_CXC + '/gettrxs', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(Data)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );
  }

  prepararBatchDocs(AppCtx, filtro)
  {
    return axios.post(API_URL_CXC + '/gettrxsbatchdocs', {
        AppCtx: `${AppCtx.codigo}`,
        Data: `${JSON.stringify(filtro)}`
    }, { headers: authHeader(), responseType: 'blob' }).then(response => {
      return response;
      }
    );
  }

  getResumenSaldosPendienteAplicar(AppCtx, IdMoneda) {
    let filtro = {
      id_moneda: `${IdMoneda}`
    };

    return axios.post(API_URL_CXC + '/getresumensaldospendientesaplicar', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(filtro)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );    
  }  

  getResumenSaldosCtaHabiente(AppCtx, IdMoneda) {
    let filtro = {
      id_moneda: `${IdMoneda}`
    };

    return axios.post(API_URL_CXC + '/getresumensaldosxctahabiente', {
      AppCtx: `${AppCtx.codigo}`,
      Data:`${JSON.stringify(filtro)}`
    }, { headers: authHeader() }).then(response => {
    return response.data;
    }
    ).catch(
    err => {
      if(err.response){
        console.log(err.response);
        return Promise.reject(err.response.data);          
      } else {
        return Promise.reject(ERR_MSG_CONEXION);
      }        
    }
    );    
  }

  getCaculoPreliminarMora(AppCtx, Condiciones, Ctahabientes){
      return axios.post(API_URL_CXC + '/calculoemisionmora', {
        AppCtx: `${AppCtx.codigo}`,
        Condiciones: `${JSON.stringify(Condiciones)}`,
        CtaHabientes:`${JSON.stringify(Ctahabientes)}`
      }, { headers: authHeader() }).then(response => {
      return response.data;
      }
      ).catch(
      err => {
        if(err.response){
          console.log(err.response);
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
      );    
  }
}

export default  new CxcService();
